<template>
    <div>
        <div class="card-titulo d-flex justify-content-between align-items-center">
            <button type="button" class="btn btn-sm" aria-label="Left Align"  @click.prevent.stop="$router.go(-1)">
                <span class="fa fa-chevron-left fa-lg" aria-hidden="true"></span> Voltar </button>
            <h1>BOLETIM</h1>
            <i></i>
        </div>

        <div class="cards card-horario">
            <div>
                <h2>{{matricula.turma.turm_identificacao}}</h2>
                <h1 v-if="infoEscola.esc_tipo === 1">{{matricula.turma.serie.ser_descricao}}</h1>
                <h1>{{matricula.turma.curso.cur_nome}}</h1>
            </div>
            <div class="d-flex flex-row justify-content-between">
                <div class="d-flex flex-row">
                    <div class="d-flex flex-column">
                        <small>Matrícula</small>
                        <span>{{matricula.matri_matricula}}</span>
                    </div>
                </div>
            </div>
        </div>        
        <template v-for="(item,idx) in boletim">
            <div class="tab-content" :key="idx">
                <div class="row grid-titulo d-flex">
                    <div style="flex-grow: 1;">
                        <span>DISCIPLINA</span>
                    </div>
                    <div class="text-center" style="width: 50px">
                        <span>1º BIM</span>
                    </div>
                    <div class="text-center" style="width: 30px">
                        <span>F</span>
                    </div>
                    <div class="text-center" style="width: 50px">
                        <span>2º BIM</span>
                    </div>
                    <div class="text-center" style="width: 30px">
                        <span>F</span>
                    </div>
                    <div class="text-center" style="width: 50px">
                        <span>1º SEM</span>
                    </div>
                    <div class="text-center" style="width: 50px">
                        <span>R1ºS</span>
                    </div>
                    <div class="text-center" style="width: 50px">
                        <span>MF1ºS</span>
                    </div>
                    <div class="text-center" style="width: 50px">
                        <span>3º BIM</span>
                    </div>
                    <div class="text-center" style="width: 30px">
                        <span>F</span>
                    </div>
                    <div class="text-center" style="width: 50px">
                        <span>4º BIM</span>
                    </div>
                    <div class="text-center" style="width: 30px">
                        <span>F</span>
                    </div>
                    <div class="text-center" style="width: 50px">
                        <span>2º SEM</span>
                    </div>
                    <div class="text-center" style="width: 50px">
                        <span>R2ºS</span>
                    </div>
                    <div class="text-center" style="width: 50px">
                        <span>MF2ºS</span>
                    </div>
                    <div class="text-center" style="width: 40px">
                        <span>PF</span>
                    </div>
                    <div class="text-center" style="width: 40px">
                        <span>MF</span>
                    </div>
                    <div class="text-center" style="width: 50px">
                        <span>CHA</span>
                    </div>
                    <div class="text-right" style="width: 80px">
                        <span>RF</span>
                    </div>
                </div>
                <template v-for="(nota,idxn) in item.notas">
                    <div class="row grid-dados d-flex" :key="idxn">
                        <div class="d-flex flex-column justify-content-start align-items-start" style="flex-grow: 1;">
                            <span>
                                <span class="badge badge-primary">{{nota.disp_disciplina}}</span>
                                {{nota.disp_nome}}
                            </span> 
                            <small>{{nota.pes_nome}}</small>
                        </div>
                        <div class="text-center" style="width: 50px">
                            <span :class="nota.media_eta_1 < nota.crit_media_final ? 'text-danger' : 'text-primary'">{{nota.media_eta_1 | moeda}}</span>
                        </div>
                        <div class="text-center" style="width: 30px">
                            <span>{{nota.faltas_eta_1}}</span>
                        </div>
                        <div class="text-center" style="width: 50px">
                            <span :class="nota.media_eta_2 < nota.crit_media_final ? 'text-danger' : 'text-primary'">{{nota.media_eta_2 | moeda}}</span>
                        </div>
                        <div class="text-center" style="width: 30px">
                            <span>{{nota.faltas_eta_2}}</span>
                        </div>
                        <div class="text-center" style="width: 50px">
                            <span :class="nota.media_sem_1 < nota.crit_media_final ? 'text-danger' : 'text-primary'">{{nota.media_sem_1 | moeda}}</span>
                        </div>
                        <div class="text-center" style="width: 50px">
                            <span :class="nota.rec_sem_1 < nota.crit_media_final ? 'text-danger' : 'text-primary'">{{nota.rec_sem_1 | moeda}}</span>
                        </div>
                        <div class="text-center" style="width: 50px">
                            <span>{{nota.media_fin_sem_1 | moeda}}</span>
                        </div>
                        <div class="text-center" style="width: 50px">
                            <span :class="nota.media_eta_3 < nota.crit_media_final ? 'text-danger' : 'text-primary'">{{nota.media_eta_3 | moeda}}</span>
                        </div>
                        <div class="text-center" style="width: 30px">
                            <span>{{nota.faltas_eta_3}}</span>
                        </div>
                        <div class="text-center" style="width: 50px">
                            <span :class="nota.media_eta_4 < nota.crit_media_final ? 'text-danger' : 'text-primary'">{{nota.media_eta_4 | moeda}}</span>
                        </div>
                        <div class="text-center" style="width: 30px">
                            <span>{{nota.faltas_eta_4}}</span>
                        </div>
                        <div class="text-center" style="width: 50px">
                            <span :class="nota.media_sem_2 < nota.crit_media_final ? 'text-danger' : 'text-primary'">{{nota.media_sem_2 | moeda}}</span>
                        </div>
                        <div class="text-center" style="width: 50px">
                            <span :class="nota.rec_sem_2 < nota.crit_media_final ? 'text-danger' : 'text-primary'">{{nota.rec_sem_2 | moeda}}</span>
                        </div>
                        <div class="text-center" style="width: 50px">
                            <span>{{nota.media_fin_sem_2 | moeda}}</span>
                        </div>
                        <div class="text-center" style="width: 40px">
                            <span :class="nota.prova_final < nota.crit_media_final ? 'text-danger' : 'text-primary'">{{nota.prova_final | moeda}}</span>
                        </div>
                        <div class="text-center" style="width: 40px">
                            <span :class="nota.media_final < nota.crit_media_final ? 'text-danger' : 'text-primary'">{{nota.media_final | moeda}}</span>
                        </div>
                        <div class="text-center" style="width: 50px">
                            <span>{{nota.ch | moeda}}</span>
                        </div>
                        <div class="text-right" style="width: 80px">
                            <span>{{nota.situacao2}}</span>
                        </div>
                    </div>
                </template>
            </div>
        </template>
    </div>
</template>

<script>
import axios from '@/http'
import { mapState } from 'vuex'

export default {
    Name: 'PortalBoletim',
    components: {

    },
    data () {
        return {
            boletim: []
        }
    },
    methods: {
        async getBoletim () {
            await axios.get('/boletim',
            {
                params: {
                    entidade: this.$route.params.entidade,
                    escola: this.$route.params.escola,
                    anoletivo: this.$route.params.ano_letivo,
                    turma: this.$route.params.turma,
                    aluno: this.$route.params.aluno
                }
            })
            .then(response => {
                this.boletim = response.data.data;            
            })
        }

    },
    mounted () {
        this.getBoletim();    
    },
    computed: {
        ...mapState('Portal',['matriculas','infoEscola']),
        matricula () {
            return this.matriculas.filter(item => item.turma.turm_codigo == this.$route.params.turma)[0];
        },
    }
}
</script>

<style scoped>
.grid-titulo > div > span,
.grid-dados > div > span {
    font-size: 12px;
}

</style>
